<template>
  <div v-show="isActive">
    <slot>
      <div class="card">
        <div class="card-body">
          <div class="row mt-4">
            <div class="col-sm-12 col-md-6"></div>
          </div>
          <!-- Table -->
          <div class="table-responsive mb-0">
            <b-table
              :items="tabData"
              :fields="fields"
              responsive="sm"
              :per-page="perPage"
              :current-page="currentPage"
            >
              <template #table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                </div>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </slot>
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String, default: "All Campaigns" },
    tabData: { type: Array, default: () => [] },
    currentPage: { type: Number, default: 1 },
    perPage: { type: Number, default: 10 },
    pageOptions: { type: Array, default: () => [10, 25, 50, 100] },
    fields: { type: Array, default: () => [] },
    filter: null,
  },

  data() {
    return { isActive: true };
  },

  computed: {
    rows() {
      return this.tabData.length;
    },
  },
};
</script>

<style>
</style>